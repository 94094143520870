



















import { defineComponent, onMounted, ref, watch } from "@vue/composition-api"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Loading from "@/components/Loading.vue"
import { goToPath, goToRoute, isPathForRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import { appContainer } from "@/containers/AppContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import ApplicationError from "@/models/Errors/ApplicationError"

const sofieTokenProvider = new SofieTokenProvider()

export default defineComponent({
  components: {
    I18nFormattedMessage,
    Loading,
  },
  props: {
    redirect: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const {
      isAuthenticating,
      isAuthenticated,
      setIsAuthenticating,
      setIsAuthenticated,
      getCurrentTenant,
    } = appContainer.useContainer()
    const { formatMessage: i18nFormattedMessage } = i18nContainer.useContainer()
    const { getAndSetMeetingDetails } = meetingContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    setIsAuthenticating(true)

    const errorMessage = ref("")

    const shouldLoadMeetings = () =>
      !isPathForRoute(props.redirect, RouteName.TabConfig) &&
      !isPathForRoute(props.redirect, RouteName.RemoveConfig) &&
      !isPathForRoute(props.redirect, RouteName.MeetingCreationFromExternal) &&
      !isPathForRoute(props.redirect, RouteName.PersonalSearch) &&
      !isPathForRoute(props.redirect, RouteName.PersonalNotification) &&
      !isPathForRoute(props.redirect, RouteName.PersonalConfirmMemo)

    const loadMeetings = async () => {
      if (!shouldLoadMeetings()) return
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      await getAndSetMeetingDetails(project.siteId, context.entityId)
    }

    const authenticateAsync = async () => {
      try {
        await sofieTokenProvider.getTokenAsync()
        await getCurrentTenant()
        await loadMeetings()
        setIsAuthenticated(true)

        if (props.redirect) goToPath(props.redirect)
        else {
          goToRoute({ name: RouteName.MeetingSeries, params: {} })
        }
      } catch (error) {
        if (error instanceof ApplicationError) {
          throw error
        }
        errorMessage.value =
          error instanceof Error
            ? error.message
            : i18nFormattedMessage({
                id: "Authenticate.unknownError",
                defaultMessage: "予期せぬエラーが発生しました",
              })
        throw error
      } finally {
        setIsAuthenticating(false)
      }
    }

    watch(
      () => isAuthenticated.value,
      newValue => {
        if (newValue) return
        authenticateAsync()
      }
    )

    onMounted(authenticateAsync)

    return { isAuthenticating, isAuthenticated, errorMessage }
  },
})
